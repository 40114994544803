import { DateTime } from 'luxon'

export default function useDates() {
  const formatDate = (date: string | Date | DateTime) => {
    if (typeof date === 'string') {
      const jsDate = new Date(date)
      return DateTime.fromJSDate(jsDate).toLocaleString()
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date).toLocaleString()
    }
    return date.toLocaleString()
  }

  const formatDateTime = (date_time: string | Date | DateTime, type = '') => {
    let time = date_time
    if (typeof date_time === 'string') {
      time = DateTime.fromISO(date_time)
    }
    if (date_time instanceof Date) {
      time = DateTime.fromJSDate(date_time)
    }

    switch (type) {
      case 'date':
        return time.toLocaleString(DateTime.DATE_MED)
      case 'md':
        return time.toLocaleString(DateTime.DATETIME_MED)
      case 'sm':
        return time.toLocaleString(DateTime.TIME_SIMPLE)
      default:
        return time.toLocaleString(DateTime.DATETIME_FULL)
    }
  }

  return {
    formatDate,
    formatDateTime,
  }
}
